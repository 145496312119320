<template>
  <div>
    <div class="row index">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Create Banner Ad</strong>
            </div>
            <div class="text-muted">Create new Banner Ad</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Campaign">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Campaign of the Banner Ad.</span>
                      </template>
                      <a-select
                        v-decorator="['campaign', { initialValue: initialValue, rules: [{ required: true, message: 'Please select campaign.' }] }]"
                        placeholder="Please select a campaign" :showSearch="true" :filterOption="true"
                        optionFilterProp="children">
                        <a-select-option v-for="obj in ortbCampaign.list.filter(ele => ele.Status === 0)" :key="obj.Id">
                          [{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Banner Ad's name.</span>
                      </template>
                      <a-input type="text" placeholder="Banner Ad Name" v-decorator="['name', {
                        rules: [
                          { required: true, message: 'Name is required.' },
                          { max: 64, message: 'Maximum 64 characters allowed.' }
                        ]
                      }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Default CPM">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Default cost per mile.</span>
                      </template>
                      <a-input type="number" min="0" max="100" step="0.0001" placeholder="Default CPM"
                        v-decorator="['defaultCPM', { initialValue: 1.000, rules: [{ validator: defaultCpmValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Domain/Brand">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Domain or brand name for your Banner Ad.</span>
                      </template>
                      <a-input placeholder="Ad Domain/Brand" v-decorator="['adDomainBrand', {
                        rules: [
                          { max: 256, message: 'Maximum 256 characters allowed.' }
                        ]
                      }]">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad App Bundle">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Application identifier. On Android, this should be bundle or package name (e.g.,
                          com.foo.mygame), on iOS, this is numeric ID.</span>
                      </template>
                      <a-input type="text" placeholder="Ad App Bundle" v-decorator="['adAppBundle', {
                        rules: [
                          { max: 64, message: 'Maximum 64 characters allowed.' }
                        ]
                      }]">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Destination URL">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Destination URL that loads when user clicks your Banner Ad.</span>
                      </template>
                      <a-input type="url" placeholder="Destination URL" v-decorator="['destinationURL', {
                        rules: [
                          { required: true, message: 'Destination URL is required.' },
                          { max: 512, message: 'Maximum 512 characters allowed.' }
                        ]
                      }]">
                        <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
                          <template slot="title">
                            <b>{cc}</b> - Lowercase country code will be replaced.<br>
                            <b>{CC}</b> - Uppercase country code will be replaced.<br>
                            <b>{Country}</b> - Capitalized country name will be replaced.<br>
                            <b>{ip}</b> - IP will be replaced.<br>
                            <b>{ua}</b> - User-agent string will be replaced.<br>
                            <b>{width}</b> - Width will be replaced.<br>
                            <b>{height}</b> - Height will be replaced.<br>
                            <b>{site_url}</b> - Site URL will be replaced.<br>
                            <b>{app_bundle}</b> - App Bundle will be replaced.<br>
                            <b>{app_name}</b> - App Name will be replaced.<br>
                            <b>{app_store_url}</b> - App Store URL will be replaced.<br>
                            <b>{cb}</b> - Cache buster: a uniquely generated character sequence for each link in order to
                            prevent page caching.<br>
                            <b>{timestamp}</b> - Timestamp generated at the time of transaction.<br>
                            <b>{schain}</b> - Serialized schain parameter.<br>
                            <b>{lat}</b> - Latitude will be replaced.<br>
                            <b>{lon}</b> - Longitude will be replaced.<br>
                            <b>{ifa}</b> - Identifier for Advertisers will be replaced.<br>
                            <b>{cid}</b> - Click ID will be replaced.<br>
                          </template>
                          <a-icon type="code" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Limits</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bids Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of bids per day. The system will even out distribution of bids throughout
                          the day, ie. "day shaping". Set to 0 for unlimited bids.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Bids Daily Limit"
                        v-decorator="['bidsDailyLimit', { initialValue: 0 }]" />
                    </a-tooltip>
                  </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impressions Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of impressions per day. The system will even out distribution of impressions throughout the day, ie. "day shaping". Set to 0 for unlimited impressions.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Impressions Daily Limit"
                        v-decorator="['impressionsDailyLimit', { initialValue: 0 }]" />
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Clicks Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of clicks allowed per day. The system will even out distribution of clicks throughout
                          the day, ie. "day shaping". Set to 0 for unlimited clicks.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Clicks Daily Limit"
                        v-decorator="['clicksDailyLimit', { initialValue: 0 }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Spent Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum daily amount (USD) to spend on Banner Ad. Set to 0 for unlimited amount.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Spent Daily Limit"
                        v-decorator="['spentDailyLimit', { initialValue: 0 }]" />
                    </a-tooltip>
                  </a-form-item>
                </div>
                </div>
              <a-divider style="margin-top: 34px;">Banner Position</a-divider>
              <Bannerposition v-on:setPosition="setAdPosition($event)" :positionKey="bannerPosition" />
              <a-divider style="margin-top: 34px;">Categories & Ad Attributes</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelColCat" :wrapper-col="wrapperColCat" label="Ad IAB Categories">
                    <a-tree checkable v-model="checkedKeys" :auto-expand-parent="autoExpandParent"
                      :selected-keys="selectedKeys" :tree-data="treeData" @expand="onExpand" @select="onSelect"
                      optionFilterProp="children" />
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelColCat" :wrapper-col="wrapperColCat" label="Ad Attributes">
                    <a-tree class="break-line" checkable v-model="checkedAds" :selected-keys="selectedAdKeys"
                      :tree-data="adTreeData" @expand="onExpand" @select="onAdSelect" optionFilterProp="children" />
                  </a-form-item>
                </div>
              </div>
              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Create Banner Ad</button>
                  <button type="button" @click="$router.push({ name: 'bannerads' })"
                    class="btn btn-light px-5 ml-2">Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getIabCategories } from '@/api/info'
import { createBannerAd } from '@/api/display/bannerad'
import adTreeData from '@/views/display/data/blockedAds.json'
import adPositions from '@/views/display/data/adPosition.json'

export default {
  components: {
    Bannerposition: () => import('@/components/custom/banner-position/index.vue'),
  },
  computed: {
    ...mapState(['ortbCampaign']),
    initialValue() {
      if (this.$route.params.campaignId) {
        const campaign = this.ortbCampaign.list.find(ele => ele.Id === parseInt(this.$route.params.campaignId))
        return campaign === undefined ? [] : campaign.Id
      } else {
        return []
      }
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      checkedKeys: [],
      checkedAds: [],
      selectedAdKeys: [],
      autoExpandParent: false,
      selectedKeys: [],
      bannerPosition: 0,
      adPositions: adPositions,
      treeData: [],
      adTreeData: adTreeData,
      adCreated: false,
      link: process.env.NODE_ENV === 'production' ? window.location.origin : 'https://dev.rtb.me',
      unformattedResponse: [],
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      creativesData: [],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      labelColCat: {
        xs: { span: 18 },
        sm: { span: 5 },
      },
      wrapperColCat: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    }
  },
  watch: {
    width() {
      if (this.width < 1515) {
        this.labelCol = {
          xs: { span: 24 },
          sm: { span: 9 },
        }
        this.wrapperCol = {
          xs: { span: 24 },
          sm: { span: 10 },
        }
      }
      if (this.width > 1515) {
        this.labelCol = {
          xs: { span: 24 },
          sm: { span: 5 },
        }
        this.wrapperCol = {
          xs: { span: 24 },
          sm: { span: 16 },
        }
      }
    },
  },
  methods: {
    getDimensions() {
      this.width = document.documentElement.clientWidth
      this.height = document.documentElement.clientHeight
    },
    setAdPosition(key) {
      this.bannerPosition = key
    },
    bytesToSize(bytes) {
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
      if (i === 0) return bytes + ' ' + sizes[i]
      return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i]
    },
    creativeType(type) {
      if (type === 0) {
        return 'PNG'
      } else if (type === 1) {
        return 'JPEG'
      } else if (type === 2) {
        return 'MPEG4'
      } else if (type === 3) {
        return 'WEBM'
      }
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const parentSelectedIab = this.checkedKeys.filter(checkedKey => this.unformattedResponse.includes(checkedKey))
          const array = this.treeData.map(element => parentSelectedIab.includes(element.key) ? element.children : []).flat().map(el => el.key)
          const newArr = this.checkedKeys.filter(el => !array.includes(el))
          values.AdPosition = this.bannerPosition
          values.Creatives = []
          values.AdIabCategories = newArr
          values.AdAttributes = this.checkedAds
          if (values.destinationURL !== '' && values.destinationURL) {
            values.destinationURL = values.destinationURL.replace(/\s/g, '')
          }
          createBannerAd(values).then(response => {
            if (response.Status === 'Ok') {
              this.$notification.success({
                message: 'Banner Ad Created',
                description: `Banner Ad ${response.Bannerad.Name} has been successfully created.`,
              })
              this.adCreated = true
              // this.$router.push('/display/bannerad/update/' + response.Bannerad.Id)
              this.$router.push({
                name: 'bannerad-update',
                params: { id: response.Bannerad.Id },
              }).catch(() => {
              })
            } else if (typeof response.type !== 'undefined' && response.type === 'error') {
              this.$notification.success({
                message: 'Banner Ad Created',
                description: 'Banner Ad has been successfully created.',
              })
            }
          }).catch(err => console.log(err))
          // const parentSelectedIab = this.checkedKeys.filter(checkedKey => this.unformattedResponse.includes(checkedKey))
          // const array = this.treeData.map(element => parentSelectedIab.includes(element.key) ? element.children : []).flat().map(el => el.key)
          // const newArr = this.checkedKeys.filter(el => !array.includes(el))
          // values.AdIabCategories = newArr
          // values.BlockedAdAttributes = this.checkedAds
          // this.$store.dispatch('bannerad/CREATE_AD', { payload: values, callback: function() { this.$router.push('/bannerads') }.bind(this) })
        }
      })
    },
    onAdSelect(selectedAdKeys, info) {
      // console.log('onSelect', info)
      this.selectedAdKeys = selectedAdKeys
    },
    onCheck(checkedKeys) {
      // console.log('onCheck', checkedKeys)
      this.checkedKeys = checkedKeys
    },
    onBlockedAdCheck(checkedAds) {
      // console.log('onCheck', checkedAds)
      this.checkedAds = checkedAds
    },
    onExpand(expandedKeys) {
      // console.log('onExpand', expandedKeys)
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onSelect(selectedKeys, info) {
      // console.log('onSelect', info)
      this.selectedKeys = selectedKeys
    },
    defaultCpmValidator(rule, value, message) {
      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Default CPM can not be negative.')
      }
      message()
    },
  },
  mounted() {
    window.addEventListener('resize', this.getDimensions)
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions)
  },
  created() {
    getIabCategories().then(response => {
      response.forEach(element => {
        this.unformattedResponse.push(element.Name)
      })
      const formattedResponse = []
      response.forEach((el, id) => {
        const temp = {
          title: '(' + el.Name + ')' + ' ' + el.Title,
          key: el.Name,
          children: [],
        }
        el.Subcategories.forEach((element) => {
          temp.children.push({
            title: '(' + element.Name + ')' + ' ' + element.Title,
            key: element.Name,
          })
        })
        formattedResponse.push(temp)
        this.treeData = formattedResponse
      })
    })
    this.$store.dispatch('ortbCampaign/LOAD_CAMPAIGNS_SHORT')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}

.index {
  position: relative;
  z-index: 1;
}

.image-preview {}

.delete-button {
  border: none;
  background: none;
  -webkit-transition: color .15s;
}

.delete-button:hover {
  color: #f5222e;
  transition: all .15s ease-in;
  transition: all .15s ease-out;
}

.creatives-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 100% !important;
  outline: 1px solid #e8e8e8;
  list-style: none;
  border-radius: 3px;
  padding-top: 10px;
  /* padding-bottom: 5px; */
  padding-left: 18px;
  padding-right: 18px;
  font-size: 85%;
}

.creatives-item {
  border-bottom: solid 1px #e8e8e8;
  padding-bottom: 5px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

li:last-child {
  border-bottom: none !important;
}

li:first-child {
  border-bottom: solid 1px #e8e8e8 !important;
}

.creative {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-left: 3em;
  padding-right: 3em;
}

.pointer {
  cursor: pointer;
}

.box {
  height: 8em;
  width: 8em;
  /* border: 0; */
  overflow: none;
  padding: 0;
  background-color: #f0f2f4;
}

.selected-outline {
  outline: thick solid #e5471c
}

.header-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
}

.header-bottom {
  height: 5em;
  margin-top: 3em;
}

.select-container {
  display: flex;
  justify-content: center;
  color: #f0f2f4;
}

.header-split {
  height: 3em;
  width: 8em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.header-top {
  top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #557df6;
}

.header-bottom {
  bottom: 0;
  background-color: none;
}

.footer-split {
  display: inline-flex;
  align-self: flex-end;
  height: 3em;
  width: 8em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.footer-top {
  top: 63%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #557df6;
}

.footer-bottom {
  bottom: 0;
  background-color: none;
}

.sidebar-split {
  height: 8em;
  width: 3em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.sidebar-left {
  left: 63%;
  writing-mode: vertical-lr;
  text-orientation: sideways-right;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #557df6;
}

.sidebar-right {
  right: 0;
  background-color: none;
}

.above-split {
  height: 5em;
  width: 8em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.above-top {
  top: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background-color: #557df6;
}

.above-bottom {
  bottom: 0;
  background-color: none;
}

.below-split {
  display: inline-flex;
  align-self: flex-end;
  height: 5em;
  width: 8em;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
}

.below-top {
  top: 38%;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #557df6;
}

.below-bottom {
  bottom: 0;
  background-color: none;
}

@media only screen and (min-width: 768px) {
  .w-45 {
    width: 45% !important;
  }

  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }
}

;
@media only screen and (max-width: 768px) {
  .break-line>>>li .ant-tree-node-content-wrapper {
    margin-bottom: 5px;
    padding-top: 4px;
    display: inline-block !important;
    white-space: pre-wrap !important;
    line-height: 15px !important;
    width: 90% !important;
  }

  .mobile-mb {
    margin-bottom: 1em;
  }

  .-m {
    margin-right: -4rem;
  }

  .centered {
    justify-content: space-evenly;
  }

  .creative {
    display: flex;
    flex-direction: column;
  }
}

.creative {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-left: 0em;
  padding-right: 0em;
}

.creatives-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 100% !important;
  /* outline: none; */
  list-style: none;
  border-radius: 3px;
  padding-top: 10px;
  /* padding-bottom: 5px; */
  padding-left: 18px;
  padding-right: 18px;
  font-size: 85%;
}

.creatives-item {
  border-bottom: solid 1px #e8e8e8;
  padding-bottom: 5px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box {
  height: 8em;
  width: 8em;
  border: 0;
  padding: 0;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  background: #f0f2f4;
}

;
</style>
